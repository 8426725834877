<template>
  <div id="entry">
    <div class="home-screen">
    </div>
    <div class="content">
      <div class="nav">
        <div class="home-links">
          <a class="discord" :href="$store.state.socialMedia.discord" target="_blank"></a>
          <br/>
          <a class="twitter" :href="$store.state.socialMedia.twitter" target="_blank"></a>
          <a class="instagram" :href="$store.state.socialMedia.instagram" target="_blank"></a>
        </div>
      </div>
      <div class="nav" id="about"></div>
      <div class="nav" id="preview">
        <div id="preview-image-container">
          <div id="preview-img"></div>
          <div id="preview-border-top">
            <div></div>
          </div>
          <div id="preview-border-bottom">
            <div></div>
          </div>
        </div>
      </div>
      <div class="nav" id="roadmap"></div>
      <div class="nav" id="team">
        <ul>
          <li v-for="(member, _, index) in $store.getters.getTeam"
              :key="index">
            <team-card class="team-card-item"
                       :twitter="member.twitter"
                       :instagram="member.instagram"
                       height="24vh"
                       :img="member.image"
                       :name="member.name"
                       :position="member.position"/>
          </li>
        </ul>
      </div>
      <div class="nav" id="faq">
        <v-card class="faq-panel" flat color="transparent">
          <v-card-text>
            <v-expansion-panels>
              <v-expansion-panel :key="index"
                                 v-for="(q, index) in $store.getters.getFaq">
                <v-expansion-panel-header>
                  <span style="font-size: larger">
                  {{ q.question }}
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <p style="font-size: large"
                     :key="i"
                     v-for="(text, i) in splitLines(q.answer)">
                    <span v-html="extractLinks(text)"></span>
                  </p>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import TeamCard from '@/components/TeamCard/TeamCard'

export default {
  name: 'StandardHome',
  components: {
    TeamCard
  },
  methods: {
    splitLines: (text) => {
      return text.split('\n')
    },
    extractLinks: (input) => {
      const regex = /(http(?:[s]?):\/\/)?(([a-zA-Z0-9]+\.)([a-zA-Z0-9]+)\/([a-zA-Z\-0-9]+\/?)+)/gm
      return input.replaceAll(regex, (...params) => {
        const url = (typeof params[1] === 'undefined' ? 'https://' : params[1]) + params[2]
        return '<a href="' + url + '" target="_blank">' + params[2] + '</a>'
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "/src/assets/styles/variables";

$backgroundWidth: 1903;
$backgroundHeight: 8218;

.faq-panel {
  width: #{$backgroundWidth}px;
  margin: 10% auto 0 auto;
  opacity: 0.9;
  padding: 0 10%;
}

#entry {
  background-image: url("/public/background/gradient/pc_a.png");
  background-position: bottom;
  background-repeat: repeat-x;
}

#team {
  text-align: center;
  padding-top: 10vw;
}

#team img {
  margin-bottom: 1%;
}

#team li {
  display: inline;
}

#team li:nth-of-type(even):after {
  content: '\A';
  white-space: pre;
}

.team-card-item {
  margin: 1% 5%;
}

.team-card-item:nth-of-type(1), .team-card-item:nth-of-type(2) {
  margin-top: 0;
}

.content {
  position: absolute;
  top: 0;
  height: 100%;
}

#preview-border-top {
  position: relative;
  bottom: 104%;
  height: 0;
  overflow: visible;
}

#preview-border-top div {
  height: 4vw;
  background-image: url("/public/preview/pc-border-top.png");
  background-repeat: repeat-x;
  background-size: contain;
}

#preview-border-bottom {
  position: relative;
  bottom: 3%;
  height: 0;
  overflow: visible;
}

#preview-border-bottom div {
  height: 4vw;
  background-image: url("/public/preview/pc-border-bottom.png");
  background-repeat: repeat-x;
  background-size: contain;
}

#preview-image-container {
  padding-top: 5%;
  padding-bottom: 5%;
  overflow: hidden;
  width: 100%;
  height: 100%;
  max-height: 1223px;
}

#preview-img {
  height: 100%;
  min-width: 20000px;
  width: 200%;
  background-image: url("/public/preview/preview_1-4.jpg");
  background-repeat: repeat-x;
  animation: slide 30s linear infinite;
}

@keyframes slide {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-2690px, 0);
  }
}

.home-screen {
  margin: 0 auto;
  max-width: #{$backgroundWidth}px;
  aspect-ratio: #{$backgroundWidth}/#{$backgroundHeight};
  background-image: url("/public/background/pc_1-6.png");
  background-position-x: center;
  background-size: cover;
}

.home-screen img {
  padding: 0;
  margin: 0;
  border: 0;
}

div.nav {
  width: 100%;
  padding-top: 5%;
  height: 15%;
  text-align: center;
}

.nav:nth-of-type(2) { // about
  height: 14%;
}

.nav:nth-of-type(3) { // preview
  padding-top: 100px;
  height: 20%;
}

.nav:nth-of-type(4) { // roadmap
  height: 15%;
}

.nav:nth-of-type(5) { // team
  height: 15%;
}

.home-links {
  text-align: center;
  width: 100%;
  position: relative;
  top: 65%
}

.home-links a {
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  margin: 0 10px;
  width: 120px;
  height: 80px;
  transition: transform 0.25s ease;
}

.home-links a:hover {
}

.home-links a.twitter {
  background-image: url("../../../public/button/twitter.png");
}

.home-links a.twitter:hover {
  transform: rotate(2deg);
}

.home-links a.instagram {
  background-image: url("../../../public/button/instagram.png");
}

.home-links a.instagram:hover {
  transform: rotate(-2deg);
}

.home-links a.discord {
  background-image: url("../../../public/button/discord.png");
  width: 375px;
  height: 100px;
  margin-top: 10px;
}

.home-links a.discord:hover {
  transform: rotate(-1deg);
}
</style>
