<template>
  <div style="position: fixed; width: 100%;">
    <phone-navigation v-if="width === 'xs'"
                      :links="links"/>
    <standard-navigation v-else
                         :links="links"/>
  </div>
</template>

<script>
import StandardNavigation from '@/components/navigation/StandardNavigation'
import PhoneNavigation from '@/components/navigation/PhoneNavigation'

export default {
  name: 'FluffyNavigation',
  components: {
    PhoneNavigation,
    StandardNavigation
  },
  data: function () {
    return {
      links: {
        left: [
          {
            href: '/',
            title: 'About',
            img: 'button/about.png'
          },
          {
            href: '/staking',
            title: 'Staking',
            img: 'button/staking.png'
          }
        ],
        right: []
      }
    }
  },
  computed: {
    width: function () {
      return this.$vuetify.breakpoint.name
    }
  }
}
</script>

<style scoped>
</style>
