<template>
  <v-app id="app" class="ff-bg-blue2">
    <v-main>
      <transition name="fade" mode="in-out">
        <v-container fluid style="padding: 0">
          <router-view/>
        </v-container>
      </transition>
    </v-main>
    <fluffy-navigation/>
    <fluffy-footer/>
  </v-app>
</template>

<style lang="scss">
@import "assets/styles/variables";

//@font-face {
//  font-family: "ChristmassSheep";
//  src: local("ChristmassSheep"),
//  url('../public/fonts/ChristmasSheep.ttf') format("truetype");
//}

@font-face {
  font-family: "JackArmstrongBB";
  src: url("//db.onlinewebfonts.com/t/71976204cf6d53991ececd406824ec45.eot");
  src: url("//db.onlinewebfonts.com/t/71976204cf6d53991ececd406824ec45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/71976204cf6d53991ececd406824ec45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/71976204cf6d53991ececd406824ec45.woff") format("woff"), url("//db.onlinewebfonts.com/t/71976204cf6d53991ececd406824ec45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/71976204cf6d53991ececd406824ec45.svg#JackArmstrongBB") format("svg");
}

//body::-webkit-scrollbar, .content::-webkit-scrollbar {
//  display: none;
//}

/* Hide scrollbar for IE, Edge and Firefox */
//body, .content {
//  -ms-overflow-style: none; /* IE and Edge */
//  scrollbar-width: none; /* Firefox */
//}

#app {
  font-family: "JackArmstrongBB";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  background-color: $blue2;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.content {
  position: fixed;
  width: 100%;
}
</style>

<script>
import FluffyFooter from '@/components/FluffyFooter'
import FluffyNavigation from '@/components/FluffyNavigation'

export default {
  components: {
    FluffyNavigation,
    FluffyFooter
  },
  computed: {
    contentHeight: function () {
      const head = this.$store.state.headerHeight
      return 'bottom: -5px; top: 0; padding: ' + head + 'px 0 0 0;'
    }
  },
  methods: {
    onContentResize: function (width, height) {
      this.$store.commit('setContentHeight', height)
      this.$store.commit('setContentWidth', width)
    }
  },
  mounted: function () {
    const that = this
    window.onresize = function (event) {
      that.onContentResize(event.currentTarget.innerWidth, event.currentTarget.innerHeight)
    }
  }
}
</script>
