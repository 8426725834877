<template>
  <phone-home v-if="width === 'xs'" />
  <standard-home v-else />
</template>

<script>
import StandardHome from '@/components/home/StandardHome'
import PhoneHome from '@/components/home/PhoneHome'

export default {
  name: 'HomeView',
  components: {
    PhoneHome,
    StandardHome
  },
  computed: {
    width: function () {
      return this.$vuetify.breakpoint.name
    }
  }
}
</script>

<style scoped>
</style>
