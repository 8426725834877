import Vue from 'vue'
import Vuex from 'vuex'
import { SocialMedia } from '@/store/SocialMedia'
import { Metamask } from '@/store/Metamask'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    viewHeight: 0,
    contentWidth: 0,
    contentHeight: 0,
    footerHeight: 0,
    headerHeight: 0,
    cookies: false,
    faq: [
      {
        question: 'Why free?',
        answer: 'Everyone deserves a chance to get a Fluffy Fuck, so you can feel at home in our community no matter who you are or how much funds you have.'
      },
      {
        question: 'What is the total supply of the Fluffy Fucks?',
        answer: 'There will be 6060 Fluffy Fucks available.'
      },
      {
        question: 'What percentage of the trading funds will go to animal charities?',
        answer: '20% will go to animal charity. The donations will be public on the ETH network.'
      },
      {
        question: 'How will the team work without funds?',
        answer: 'Instead of a get rich quick scheme from minting, the team will be compensated weekly from the trading volume to incentivise the project\'s longevity. As a group of freelancers, we can dedicate 100% of our time to the project.'
      },
      {
        question: 'Which animal charities will benefit from our donations?',
        answer: 'Holders will have the voting power to choose the animal charities they like the best. Charities will be voted on every 2 months.'
      },
      {
        question: 'Staking? What will be the benefits?',
        answer: 'Free mint for future generations. Join Discord for more information'
      },
      {
        question: 'Where can I connect with the community?',
        answer: 'Our main community base is Discord.'
      },
      {
        question: '2nd gen?',
        answer: 'Minting soon. Join Discord for more information'
      },
      {
        question: 'What are my ownership rights?',
        answer: 'You can make whatever personal use of your Fluffy Fucks you want under a NON-Exclusive License. The Fluffy Fucks team retains all commercial rights.'
      }
    ],
    team: {
      mark: {
        name: 'Mark Berden',
        twitter: 'https://twitter.com/MarkBerden0001',
        instagram: 'https://www.instagram.com/nemkohanincs/',
        image: '/team/mark.png',
        position: 'Founder/3d artist'
      },
      fluffyone: {
        name: 'Fluffyone',
        twitter: 'https://twitter.com/FluffyOnee',
        instagram: '',
        image: '/team/fluffyone.png',
        position: 'Art design'
      },
      kosa: {
        name: 'Peter Paul Koša',
        twitter: 'https://twitter.com/B737Sirius',
        instagram: '',
        image: '/team/kosa.png',
        position: 'Community manager'
      },
      peter: {
        name: 'Peter Berden',
        twitter: '',
        instagram: 'https://www.instagram.com/berdenpeter/',
        image: '/team/peti.png',
        position: 'Marketing'
      },
      inzenir: {
        name: 'Inzenir',
        twitter: '',
        instagram: '',
        image: '/team/inzenir.png',
        position: 'Developer'
      }
    }
  },
  getters: {
    getTeam: (state) => {
      return state.team
    },
    getFaq: (state) => {
      return state.faq
    }
  },
  mutations: {
    setContentWidth (state, value) {
      state.contentWidth = value
      recalculateViewHeight(state)
    },
    setContentHeight (state, value) {
      state.contentHeight = value
      recalculateViewHeight(state)
    },
    setFooterHeight (state, value) {
      state.footerHeight = value
      recalculateViewHeight(state)
    },
    setHeaderHeight (state, value) {
      state.headerHeight = value
      recalculateViewHeight(state)
    },
    acceptCookies (state) {
      state.cookies = true
    },
    declineCookies (state) {
      state.cookies = false
    }
  },
  actions: {},
  modules: {
    socialMedia: SocialMedia,
    metamask: Metamask
  }
})

function recalculateViewHeight (state) {
  state.viewHeight = state.contentHeight - state.footerHeight
}
