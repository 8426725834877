import Config from './RebornConfig'
import StakingConfig from './StakingConfig'

class RebornCalls {
  abi = {}

  toWei = null
  metamask = null
  val = 0

  constructor () {
    Config.load()
    StakingConfig.load()
    const tmpAbi = require('../contract/FluffyFucksReborn.json')
    this.abi = tmpAbi.abi
  }

  from (web3) {
    this.alchemyProvider = new web3.web3.eth.Contract(this.abi, Config.contract)
    this.metamask = web3.metaMaskAddress
    this.toWei = web3.web3.utils.toWei
  }

  req (params = {}) {
    const data = {
      from: this.metamask
    }

    if (typeof params === 'object') {
      for (const index in params) {
        if (index === 'value') {
          const tmpValue = Number.parseFloat(params[index])
          if (typeof this.toWei !== 'function') {
            throw new Error('toWei is not a function')
          }
          if (!Number.isNaN(tmpValue)) {
            data[index] = this.toWei(tmpValue.toString(), 'ether')
          }
        } else {
          data[index] = params[index]
        }
      }
    }

    return data
  }

  wrapCall = async function (func) {
    try {
      if (typeof func !== 'function') {
        throw new Error('Passed call is not a function')
      }
      return [true, await func()]
    } catch (e) {
      return [false, e]
    }
  }

  tokensOfOwner = async function (address) {
    const that = this
    return that.wrapCall(async function () {
      if (typeof address === 'undefined') {
        address = that.metamask
      }
      return that.alchemyProvider.methods
        .tokensOfOwner(address)
        .call(that.req({}))
    })
  }

  setApprovalForAll = async function (approve) {
    const that = this
    return that.wrapCall(async function () {
      if (typeof approve === 'undefined') {
        throw new Error('Approval is not defined')
      }
      const params = that.req({})
      const func = that.alchemyProvider.methods
        .setApprovalForAll(StakingConfig.contract, approve)
      await func.estimateGas(params)
      return func.send(params)
    })
  }
}

export default class RebornCommunication {
  calls = null

  constructor () {
    this.calls = new RebornCalls()
  }

  /**
   * @param web3
   * @returns {RebornCalls}
   */
  from (web3) {
    this.calls.from(web3)
    return this.calls
  }
}
