import Config from './StakingConfig'

class StakingCalls {
  abi = {}

  toWei = null
  metamask = null
  val = 0

  constructor () {
    Config.load()
    const tmpAbi = require('../contract/FluffyStaker.json')
    this.abi = tmpAbi.abi
  }

  from (web3) {
    this.alchemyProvider = new web3.web3.eth.Contract(this.abi, Config.contract)
    this.metamask = web3.metaMaskAddress
    this.toWei = web3.web3.utils.toWei
  }

  req (params = {}) {
    const data = {
      from: this.metamask
    }

    if (typeof params === 'object') {
      for (const index in params) {
        if (index === 'value') {
          const tmpValue = Number.parseFloat(params[index])
          if (typeof this.toWei !== 'function') {
            throw new Error('toWei is not a function')
          }
          if (!Number.isNaN(tmpValue)) {
            data[index] = this.toWei(tmpValue.toString(), 'ether')
          }
        } else {
          data[index] = params[index]
        }
      }
    }

    return data
  }

  wrapCall = async function (func) {
    try {
      if (typeof func !== 'function') {
        throw new Error('Passed call is not a function')
      }
      return [true, await func()]
    } catch (e) {
      return [false, e]
    }
  }

  active = async function () {
    const that = this
    return that.wrapCall(async function () {
      return that.alchemyProvider.methods
        .active()
        .call(that.req({}))
    })
  }

  checkIfIsApprovedByContract = async function () {
    const that = this
    return that.wrapCall(async function () {
      return that.alchemyProvider.methods
        .isStakingEnabled(that.metamask)
        .call(that.req({}))
    })
  }

  totalStaked = async function () {
    const that = this
    return that.wrapCall(async function () {
      return that.alchemyProvider.methods
        .totalStaked()
        .call(that.req({}))
    })
  }

  allTokenStakers = async function () {
    const that = this
    return that.wrapCall(async function () {
      return that.alchemyProvider.methods
        .allTokenStakers()
        .call(that.req({}))
    })
  }

  tokensStakedByAddress = async function (address = null) {
    const that = this
    address = address !== null
      ? address
      : that.metamask
    return that.wrapCall(async function () {
      return that.alchemyProvider.methods
        .tokensStakedByAddress(address)
        .call(that.req({}))
    })
  }

  stakeBatch = async function (tokens) {
    const that = this
    return that.wrapCall(async function () {
      const params = that.req({})
      const func = that.alchemyProvider.methods
        .stakeBatch(tokens)
      await func.estimateGas(params)
      return func.send(params)
    })
  }

  unstakeBatch = async function (tokens) {
    const that = this
    return that.wrapCall(async function () {
      const params = that.req({})
      const func = that.alchemyProvider.methods
        .unstakeBatch(tokens)
      await func.estimateGas(params)
      return func.send(params)
    })
  }
}

export default class StakingCommunication {
  calls = null

  constructor () {
    this.calls = new StakingCalls()
  }

  /**
   * @param web3
   * @returns {StakingCalls}
   */
  from (web3) {
    this.calls.from(web3)
    return this.calls
  }
}
