<template>
  <v-app-bar flat prominent floating>
    <v-row>
      <v-col class="left-nav pa-10">
        <router-link :to="item.href" :key="i" v-for="(item, i) in links.left">
          <img :src="item.img" :alt="item.title" />
        </router-link>
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<script>
export default {
  name: 'StandardNavigation',
  props: {
    links: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  mounted: function () {
    this.$store.commit('setHeaderHeight', this.$el.clientHeight)
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/styles/variables.scss";

.left-nav a {
  margin: 0 10px;
}

.left-nav a img {
  height: 3.5vh;
  transition: transform 0.25s ease;
}

.left-nav a img:hover {
  transform: scale(1.1) rotate(1deg);
}

.left-nav a:nth-child(1) img {
  aspect-ratio: 265/60;
}

.left-nav a:nth-child(2) img {
  aspect-ratio: 178/54;
}

.left-nav a:nth-child(3) img {
  aspect-ratio: 181/58;
}

.left-nav a:nth-child(4)  img{
  aspect-ratio: 179/54;
}
</style>
