<template>
  <v-card
      class="mx-auto"
      color="transparent"
      flat
  >
    <img class="menu-button" src="button/burger.png" @click.stop="drawer = !drawer">

    <v-navigation-drawer
        v-model="drawer"
        absolute
        temporary
        right
        color="ff-bg-teal"
        height="100vh"
    >
      <v-list
          nav
          dense
      >
        <v-list-item-group>
          <v-list-item :key="i" v-for="(item, i) in links.left">
            <v-list-item-title>
              <router-link :to="item.href">
                <img :src="item.img" :alt="item.title" />
              </router-link>
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
export default {
  name: 'PhoneNavigation',
  data: () => {
    return {
      extended: false,
      drawer: false
    }
  },
  props: {
    links: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    height: function () {
      return this.$store.state.contentHeight
    }
  },
  mounted: function () {
    this.$store.commit('setHeaderHeight', this.$el.clientHeight)
  }
}
</script>

<style scoped lang="scss">
.menu-button {
  margin: 6vw 6vw;
  aspect-ratio: 4/3;
  height: 7vw;
  float: right;
}

#toolbar ul {
  margin-top: 2vw;
  list-style: none;
  background-color: transparent;
}

a img {
  height: 8vw;
  margin-right: 20px;
}
</style>

<style lang="scss">
@import "src/assets/styles/variables";

.theme--light.v-navigation-drawer {
  background-color: rgba($teal, 0.9);
}
</style>
