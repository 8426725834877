<template>
  <div class="team-card" :style="'height: ' + height">
    <table>
      <tr>
        <td></td>
        <td class="image-td" :style="{backgroundImage: 'url(' + img + ')'}">
        </td>
        <td class="links-td">
          <div class="links">
            <a :href="instagram" v-if="instagram !== ''" class="instagram-link"></a>
            <br v-if="instagram !== ''" />
            <a :href="twitter" v-if="twitter !== ''" class="twitter-link"></a>
          </div>
        </td>
      </tr>
      <tr>
        <td></td>
        <td class="title-td">
          <div style="width: 100%; max-height: 100%; white-space: nowrap; padding-bottom: 10px">
            {{ name }}
          </div>
          <div style="width: 100%; max-height: 100%; white-space: nowrap; padding: 0; line-height: 80%">
            {{ position }}
          </div>
        </td>
        <td></td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'TeamCard',
  props: {
    height: {
      type: String,
      default: '100px'
    },
    img: {
      type: String,
      default: ''
    },
    instagram: {
      type: String,
      default: ''
    },
    twitter: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    position: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
.team-card {
  aspect-ratio: 1/1;
  display: inline-block;
}

table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

table tr:nth-of-type(1) {
  height: 90%;
}

table tr td:nth-of-type(2) {
  aspect-ratio: 1/1;
}

table tr td:nth-of-type(odd) {
  width: 1%;
  overflow: visible;
}

.image-td {
  background-size: contain;
  background-position: center;
}

.links-td {
  direction: rtl;
  white-space: break-spaces;
}

.links-td div {
  position: relative;
  top: -10%;
  left: -100%;
}

td .links {
  height: 100%;
}

.links a {
  height: 25%;
  display: inline-block;
  aspect-ratio: 1/1;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.links a:nth-of-type(1):hover {
  transform: rotate(2deg);
}

.links a:nth-of-type(2):hover {
  transform: rotate(-2deg);
}

.instagram-link {
  background-image: url("/public/button/instagram.png");
}

.twitter-link {
  background-image: url("/public/button/twitter.png");
}

.title-td {
  overflow: visible;
  vertical-align: top;
  text-transform: uppercase;
}

.title-td div {
  padding: 1% 0;
  font-size: large;
}

.title-td div:nth-of-type(1) {
  background-image: url("/public/team/nameplate.png");
  background-position: center;
  background-size: contain;
}
</style>
