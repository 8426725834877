<template>
  <v-footer padless absolute>
    <span class="footer-text">
      fluFFYfucks © {{ currentYear }}
      <span style="margin: auto 10px">|</span>
      <a class="ff-fg-yellow"
         @click="reviewCookies">
        Review cookies
      </a>
      <br/>
      <v-btn icon
             :title="link.title"
             class="ff-fg-yellow ma-1"
             target="_blank"
             x-small
             :href="link.href"
             :key="index"
             v-for="(link, index) in socialMedia">
          <v-icon style="color: #ffe771">
          {{ link.icon }}
        </v-icon>
      </v-btn>
    </span>
    <cookie-law ref="cookies"
                style="background: #FFE771FF"
                @accept="acceptCookies"
                @revoke="declineCookies"
                :buttonDecline="true">
    </cookie-law>
    <google-analytics/>
  </v-footer>
</template>

<script>
import CookieLaw from 'vue-cookie-law'
import GoogleAnalytics from '@/components/analytics/GoogleAnalytics'

export default {
  name: 'FluffyFooter',
  components: {
    GoogleAnalytics,
    CookieLaw
  },
  data: function () {
    return {
      height: null,
      scroll: null,
      socialMedia: [
        {
          icon: 'mdi-discord',
          href: this.$store.state.socialMedia.discord,
          title: 'Discord'
        },
        {
          icon: 'mdi-instagram',
          href: this.$store.state.socialMedia.instagram,
          title: 'Instagram'
        },
        {
          icon: 'mdi-twitter',
          href: this.$store.state.socialMedia.twitter,
          title: 'Twitter'
        }
      ]
    }
  },
  computed: {
    currentYear: () => {
      return (new Date()).getFullYear()
    }
  },
  methods: {
    acceptCookies: function () {
      this.$store.commit('acceptCookies')
      this.$gtag.optIn()
    },
    declineCookies: function () {
      this.$store.commit('declineCookies')
      document.cookie = ''
    },
    reviewCookies: function () {
      this.$refs.cookies.revoke()
    }
  },
  mounted: function () {
    this.height = this.$el.clientHeight
    if (localStorage.getItem('cookie:accepted') === true) {
      this.$store.commit('acceptCookies')
    }
  },
  watch: {
    height: function (nv) {
      this.$store.commit('setFooterHeight', nv)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/variables.scss";

.footer-text {
  letter-spacing: 1px;
  color: $yellow;
  width: 100%;
  background: transparent;
  position: absolute;
  bottom: 0.25vh;
}

.footer-text span {
  margin: 5px;
  background: transparent
}
</style>

<style lang="scss">
@import "~@/assets/styles/variables.scss";
.Cookie {
  border-top: solid 1px rgba(0, 0, 0, 0.3);
}

.Cookie__content {
  color: rgba(0, 0, 0, 0.75);
}

.Cookie__buttons {
}

.Cookie__buttons button:nth-child(1) {
  border: solid 1.5px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  transform: skew(10deg);
  color: rgba(0, 0, 0, 0.6)
}

.Cookie__buttons button:nth-child(1):hover {
  background: rgba(0,0,0,0.1);
}

.Cookie__buttons button:nth-child(2) {
  border: solid 3px rgba(0, 0, 0, 0.75);
  border-radius: 2px;
  background: $teal;
  color: rgba(0, 0, 0, 0.7);
  transform: skew(-10deg);
}

.Cookie__buttons button:nth-child(2):hover {
  background: $purple;
}
</style>
