export const Metamask = {
  state: () => ({
    mm: null,
    address: null,
    network: null
  }),
  mutations: {
    loginMetamask: function (state, mm) {
      state.mm = mm
      state.address = mm.metaMaskAddress
      state.network = mm.type
      localStorage.setItem('mmAddress', mm.metaMaskAddress)
    }
  },
  getters: {
    getMMAddress (state) {
      return state.address
    },
    getMMNetwork (state) {
      return state.network
    },
    savedMMAddress (state) {
      const lsmma = localStorage.getItem('mmAddress')
      return typeof lsmma !== 'undefined' && lsmma !== null && lsmma !== ''
    },
    getWeb3 (state) {
      return state.mm
    }
  }
}
