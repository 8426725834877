<template>
  <div style="display: none"></div>
</template>

<script>
import { bootstrap } from 'vue-gtag'

export default {
  name: 'GoogleAnalytics',
  computed: {
    cookies: function () {
      return this.$store.state.cookies
    }
  },
  watch: {
    cookies: function (nv) {
      this.cookiesActivation(nv)
    }
  },
  methods: {
    cookiesActivation: function (status) {
      if (status === true) {
        bootstrap().then((gtag) => {})
      } else {
        bootstrap().then((gtag) => {
          return {
            storage: 'none'
          }
        })
      }
    }
  },
  mounted: function () {
    this.cookiesActivation(this.$store.state.cookies)
  }
}
</script>

<style scoped>

</style>
