export default class Config {
  static alchemyKey = null
  static contract = null
  static network = null

  static load () {
    const config = require('../config/contractReborn/production').default
    if (process.env.NODE_ENV !== 'production') {
      const subConf = require('../config/contractReborn/' + process.env.NODE_ENV).default
      for (const index in subConf) {
        config[index] = subConf[index]
      }
    }

    for (const index in config) {
      this[index] = config[index]
    }
  }

  static getAlchemyUrl () {
    return 'https://eth-' + this.network + '.alchemyapi.io/v2/' + this.alchemyKey
  }

  static getWebsocketsUrl () {
    return 'wss://eth-' + this.network + '.alchemyapi.io/v2/' + this.alchemyKey
  }
}
