<template>
  <div>
    <div class="nav" id="home">
      <div class="home-links">
        <a class="discord" :href="$store.state.socialMedia.discord" target="_blank"></a>
        <br />
        <a class="twitter" :href="$store.state.socialMedia.twitter" target="_blank"></a>
        <a class="instagram" :href="$store.state.socialMedia.instagram" target="_blank"></a>
      </div>
    </div>
    <div class="nav" id="about"></div>
    <div class="nav" id="preview">
      <div id="preview-image-container">
        <div id="preview-img">&nbsp;</div>
      </div>
      <div id="preview-border-top">
        <div>&nbsp;</div>
      </div>
      <div id="preview-border-bottom">
        <div>&nbsp;</div>
      </div>
    </div>
    <div class="nav" id="roadmap"></div>
    <div class="nav" id="team">
      <table style="table-layout: fixed; width: 100%; height: 75vh">
        <tr style="height: 25vh">
          <td>
            <team-card class="team-card-item"
                       :twitter="$store.getters.getTeam.mark.twitter"
                       :instagram="$store.getters.getTeam.mark.instagram"
                       height="20vh"
                       :img="$store.getters.getTeam.mark.image"
                       :name="$store.getters.getTeam.mark.name"
                       :position="$store.getters.getTeam.mark.position"/>
          </td>
          <td>
            <team-card class="team-card-item"
                       :twitter="$store.getters.getTeam.fluffyone.twitter"
                       :instagram="$store.getters.getTeam.fluffyone.instagram"
                       height="20vh"
                       :img="$store.getters.getTeam.fluffyone.image"
                       :name="$store.getters.getTeam.fluffyone.name"
                       :position="$store.getters.getTeam.fluffyone.position"/></td>
        </tr>
        <tr style="height: 25vh">
          <td>
            <team-card class="team-card-item"
                       :twitter="$store.getters.getTeam.kosa.twitter"
                       :instagram="$store.getters.getTeam.kosa.instagram"
                       height="20vh"
                       :img="$store.getters.getTeam.kosa.image"
                       :name="$store.getters.getTeam.kosa.name"
                       :position="$store.getters.getTeam.kosa.position"/></td>
          <td>
            <team-card class="team-card-item"
                       :twitter="$store.getters.getTeam.peter.twitter"
                       :instagram="$store.getters.getTeam.peter.instagram"
                       height="20vh"
                       :img="$store.getters.getTeam.peter.image"
                       :name="$store.getters.getTeam.peter.name"
                       :position="$store.getters.getTeam.peter.position"/></td>
        </tr>
        <tr style="height: 25vh">
          <td colspan="2">
            <team-card class="team-card-item"
                       :twitter="$store.getters.getTeam.inzenir.twitter"
                       :instagram="$store.getters.getTeam.inzenir.instagram"
                       height="20vh"
                       :img="$store.getters.getTeam.inzenir.image"
                       :name="$store.getters.getTeam.inzenir.name"
                       :position="$store.getters.getTeam.inzenir.position"/></td>
        </tr>
      </table>
    </div>
    <div class="nav" id="faq">
      <v-card class="faq-panel" flat color="transparent">
        <v-card-text>
          <v-expansion-panels accordion>
            <v-expansion-panel :key="index"
                               v-for="(q, index) in $store.getters.getFaq">
              <v-expansion-panel-header style="padding: 2px 24px">
                  <span style="font-size: small">
                  {{ q.question }}
                  </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content style="margin-bottom: -8px; font-size: 1.2vh; line-height: 1.3vh">
                <p :key="i"
                   style="margin-bottom: 8px"
                   v-for="(text, i) in splitLines(q.answer)">
                  <span v-html="extractLinks(text)"></span>
                </p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
    </div>
    <div class="nav" id="bottom"></div>
  </div>
</template>

<script>
import TeamCard from '@/components/TeamCard/TeamCard'

export default {
  name: 'PhoneHome',
  components: {
    TeamCard
  },
  data: function () {
    return {
      screens: [
        'home',
        'about',
        'preview',
        'roadmap',
        'team'
      ]
    }
  },
  methods: {
    splitLines: (text) => {
      return text.split('\n')
    },
    extractLinks: (input) => {
      const regex = /(http(?:[s]?):\/\/)?(([a-zA-Z0-9]+\.)([a-zA-Z0-9]+)\/([a-zA-Z\-0-9]+\/?)+)/gm
      return input.replaceAll(regex, (...params) => {
        const url = (typeof params[1] === 'undefined' ? 'https://' : params[1]) + params[2]
        return '<a href="' + url + '" target="_blank">' + params[2] + '</a>'
      })
    }
  }
}
</script>

<style lang="scss">
.v-expansion-panel-content__wrap {
  margin: 0;
  padding-bottom: 8px;
}

.v-expansion-panel-content__wrap p, .v-application p {
  margin-bottom: 8px;
}

.v-expansion-panel-header {
  padding: 0 24px;
}
</style>

<style scoped lang="scss">
.nav {
  width: 100vw;
  aspect-ratio: 600/1175;
  //height: 100vh;
  background-size: contain;
  background-position: center;
}

#home {
  background-image: url("/public/background/phone/1_1.png");
}

#about {
  background-image: url("/public/background/phone/2_1.png");
}

#roadmap {
  background-image: url("/public/background/phone/4.png");
}

#team {
  background-image: url("/public/background/phone/5.png");
  padding-top: 14vh;
}

#faq {
  background-image: url("/public/background/phone/6.png");
  overflow: hidden;
}

.faq-panel {
  width: 100vw;
  opacity: 0.9;
  //padding-top: 20%;
  padding-top: 9vh;
}

#bottom {
  background-image: url("/public/background/phone/7.png");
  aspect-ratio: 600/135;
  height: auto;
  background-position: top;
  background-size: cover;
}

#preview {
  padding-top: 1%;
  padding-bottom: 4%;
}

#preview-border-top {
  position: relative;
  bottom: 96%;
  height: 0;
  overflow: visible;
}

#preview-border-top div {
  height: 100px;
  background-image: url("/public/preview/pc-border-top.png");
  background-repeat: repeat-x;
  background-size: contain;
}

#preview-border-bottom {
  position: relative;
  bottom: 7%;
  height: 0;
  overflow: visible;
}

#preview-border-bottom div {
  height: 50px;
  background-image: url("/public/preview/pc-border-bottom.png");
  background-repeat: repeat-x;
  background-size: contain;
}

#preview-image-container {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

#preview-img {
  position: relative;
  min-width: 20000px;
  background-position-y: 1.5vh;
  width: 200%;
  height: 90%;
  margin: 10% 0 0 0;
  background-image: url("/public/preview/preview_1-4.jpg");
  background-size: contain;
  background-repeat: repeat-x;
  animation: slide 30s linear infinite
}

@keyframes slide {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-2690px, 0);
  }
}

.home-screen {
  max-width: 600px;
  aspect-ratio: 600/6330;
}

.home-screen img {
  padding: 0;
  margin: 0;
  border: 0;
}

.home-screen div.nav {
  width: 100%;
  height: 20%;
  text-align: right;
}

.home-links {
  text-align: center;
  width: 100%;
  position: relative;
  top: 73%
}

.home-links a {
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  margin: 0 10px;
  width: 7vh;
  aspect-ratio: 100/70;
  transition: transform 0.25s ease;
  background-size: cover;
}

.home-links a:hover {
}

.home-links a.twitter {
  background-image: url("../../../public/button/twitter.png");
}

.home-links a.twitter:hover {
  transform: rotate(2deg);
}

.home-links a.instagram {
  background-image: url("../../../public/button/instagram.png");
}

.home-links a.instagram:hover{
  transform: rotate(-2deg);
}

.home-links a.discord {
  background-image: url("../../../public/button/discord.png");
  aspect-ratio: 368/81;
  width: 20vh;
}

.home-links a.discord:hover {
  transform: rotate(-1deg);
}
</style>
