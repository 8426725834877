<template>
  <div id="stake">
    <h1 style="color: rgba(0,0,0,.6); text-shadow: none; font-weight: bold">
      Fluffy staking
    </h1>
    <div v-if="loading" style="margin: 40vh auto">
      <div style="height: 20vh">
        Loading
      </div>
    </div>
    <div v-else>
      <div v-if="$store.getters.getMMNetwork === null">
        <vue-metamask msg="Logging in with metamask :)"
                      :init-connect="false"
                      ref="metamask"
                      @onComplete="metamaskLoginSuccess"/>
        <v-btn type="button" @click="metamaskLogin">
          Connect with Metamask
        </v-btn>
      </div>
      <v-card v-else class="staking-card" color="transparent" flat>
        <v-card-text>
          <v-tabs centered v-model="tab" class="staking-menu">
            <v-tab>My unstaked fluffs</v-tab>
            <v-tab>My staked fluffs</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <!-- Unstaked -->
            <v-tab-item>
              <div v-if="!allowedStaking">
                <br />
                <h2>
                  You have to approve our staking contract to stake your fluffs
                </h2>
                <br />
                <v-btn @click="setApproval">
                  Enable staking!
                </v-btn>
              </div>
              <div v-else-if="unstakedFluffs.length > 0">
                <br />
                  <img v-if="selectedUnstaked.length > 0" src="/button/staking/stake.png" @click="stakeFluffs" />
                  <h2 v-else>
                    Please select some fluffs that you want to stake
                  </h2>
                <br/>
                <div v-for="(f, i) in unstakedFluffs"
                     :key="i"
                     @click="toggleUnstakedFluff(f)"
                     class="fluff"
                     :class="{selected: isSelected(selectedUnstaked, f)}">
                  <div class="id">
                    {{ f }}
                  </div>
                </div>
                <br/>
                <img v-if="selectedUnstaked.length > 0" src="/button/staking/stake.png" @click="stakeFluffs" />
              </div>
              <div v-else>
                <br />
                <h2>No unstaked fluffs</h2>
              </div>
            </v-tab-item>
            <!-- Staked -->
            <v-tab-item>
              <br />
              <div v-if="stakedFluffs.length === 0">
                <h2>
                  No staked fluffs
                </h2>
              </div>
              <div v-else>
                <img v-if="selectedStaked.length > 0" src="/button/staking/unstake.png" @click="unstakeFluffs" />
                <div v-else>
                  You have to select fluffs that you want to unstake
                </div>
                <br />
                <div v-for="(f, i) in stakedFluffs"
                     :key="i"
                     @click="toggleStakedFluff(f)"
                     class="fluff"
                     :class="{selected: isSelected(selectedStaked, f)}">
                  <div class="id">
                    {{ f }}
                  </div>
                </div>
                <br />
                <img v-if="selectedStaked.length > 0" src="/button/staking/unstake.png" @click="unstakeFluffs" />
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
      <div style="height: 100px"></div>
    </div>
  </div>
</template>

<script>
import VueMetamask from 'vue-metamask'
import StakingCommunication from '@/classes/StakingCommunication'
import RebornCommunication from '@/classes/RebornCommunication'

export default {
  name: 'StakingPage',
  components: {
    VueMetamask
  },
  data: function () {
    return {
      loading: false,
      tab: null,
      unstakedFluffs: [],
      selectedUnstaked: [],
      stakedFluffs: [],
      selectedStaked: [],
      allowedStaking: false,
      stakingCommunication: null,
      rebornCommunication: null
    }
  },
  methods: {
    checkApproval: async function () {
      const isApproved = await this.stakingCommunication.from(this.$store.getters.getWeb3).checkIfIsApprovedByContract()
      if (isApproved[0] !== true) {
        alert(isApproved[1].message)
      }
      this.allowedStaking = isApproved[1]
    },
    setApproval: async function () {
      this.loading = true
      const approval = await this.rebornCommunication.from(this.$store.getters.getWeb3).setApprovalForAll(true)
      if (approval[0] !== true) {
        alert(approval[1].message)
      }
      await this.checkApproval()
      this.loading = false
    },
    isSelected: function (fluffs, id) {
      return fluffs.indexOf(id) !== -1
    },
    toggleUnstakedFluff: function (id) {
      if (this.selectedUnstaked.indexOf(id) === -1) {
        this.selectedUnstaked.push(id)
      } else {
        this.selectedUnstaked = this.selectedUnstaked.filter((val) => {
          return val !== id
        })
      }
    },
    toggleStakedFluff: function (id) {
      if (this.selectedStaked.indexOf(id) === -1) {
        this.selectedStaked.push(id)
      } else {
        this.selectedStaked = this.selectedStaked.filter((val) => {
          return val !== id
        })
      }
    },
    updateStakedFluffs: async function () {
      const tokens = await this.stakingCommunication.from(this.$store.getters.getWeb3).tokensStakedByAddress()
      if (tokens[0] !== true) {
        alert(tokens[0].message)
      }
      this.stakedFluffs = tokens[1]
    },
    updateUnstakedFluffs: async function () {
      const tokens = await this.rebornCommunication.from(this.$store.getters.getWeb3).tokensOfOwner()
      if (tokens[0] !== true) {
        alert(tokens[1].message)
      }
      this.unstakedFluffs = tokens[1]
    },
    stakeFluffs: async function () {
      if (this.selectedUnstaked.length === 0) {
        alert('You have to select some fluffs to stake them')
        return
      }
      this.loading = true
      const success = await this.stakingCommunication.from(this.$store.getters.getWeb3).stakeBatch(this.selectedUnstaked)
      if (success[0] !== true) {
        alert(success[1].message)
      } else {
        await this.updateUnstakedFluffs()
        await this.updateStakedFluffs()
      }
      this.selectedUnstaked = []
      this.loading = false
    },
    unstakeFluffs: async function () {
      if (this.selectedStaked.length === 0) {
        alert('You have to select some fluffs to unstake them')
        return
      }
      this.loading = true
      const success = await this.stakingCommunication.from(this.$store.getters.getWeb3).unstakeBatch(this.selectedStaked)
      if (success[0] !== true) {
        alert(success[1].message)
      } else {
        await this.updateStakedFluffs()
        await this.updateUnstakedFluffs()
      }
      this.selectedStaked = []
      this.loading = false
    },
    metamaskLogin: function () {
      this.$refs.metamask.init()
    },
    metamaskLoginSuccess: async function (value) {
      await this.$store.commit('loginMetamask', value)

      await this.checkApproval()

      this.updateStakedFluffs()
      this.updateUnstakedFluffs()
    }
  },
  mounted: function () {
    this.stakingCommunication = new StakingCommunication()
    this.rebornCommunication = new RebornCommunication()
  }
}
</script>

<style scoped>
#stake {
  margin-top: 100px
}

>>> .theme--light.v-tabs-items {
  background-color: transparent;
}

.staking-card {
  margin: 0 auto;
  max-width: 1000px;
}

.staking-menu {
  margin-bottom: 10px;
}

.fluff {
  cursor: pointer;
  display: inline-block;
  width: 15%;
  aspect-ratio: 1/1.15;
  margin: 2%;
  border: solid 5px transparent;
  background-image: url("/public/logo.png");
  background-size: 70%;
  background-position: center;
}

.fluff.selected {
  border: solid 5px rgba(0, 0, 255, 0.9);
}

.fluff .id {
  background: rgba(255, 255, 255, 0.5);
  height: 15%;
  position: relative;
  top: 85%;
}
</style>
